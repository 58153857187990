import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Future Champions
			</title>
			<meta name={"description"} content={"Наша академія прагне виховувати молоді таланти та забезпечувати найвищий рівень футбольної підготовки, гарантуючи, що кожен гравець максимально розкриє свій потенціал."} />
			<meta property={"og:title"} content={"Future Champions"} />
			<meta property={"og:description"} content={"Наша академія прагне виховувати молоді таланти та забезпечувати найвищий рівень футбольної підготовки, гарантуючи, що кожен гравець максимально розкриє свій потенціал."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="icon1" />
		</Components.Contact>
		<Components.Nav>
			<Override slot="link4" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Nav>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-3.jpg?v=2024-06-12T08:12:47.791Z) 50% 50% /cover repeat scroll padding-box">
			<Override slot="button" />
			<Override slot="text1">
				Ласкаво просимо до Future Champions
			</Override>
			<Override slot="text3" />
			<Override slot="text2">
				Наша академія прагне виховувати молоді таланти та забезпечувати найвищий рівень футбольної підготовки, гарантуючи, що кожен гравець максимально розкриє свій потенціал.
			</Override>
			<Override slot="text" />
			<Override slot="box1" display="none" />
			<Override slot="box" width="70%" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Про нашу Академію
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Футбольна академія Future Champions пропонує комплексне футбольне тренувальне середовище, яке підтримується передовими методиками тренерства та глибоким розумінням динаміки спорту. Наші програми розраховані на різні вікові групи, забезпечуючи базову платформу для початківців і високоінтенсивні тренування для просунутих гравців. З акцентом на дисципліні, командній роботі та постійному вдосконаленні наша академія готує спортсменів як до змагань, так і до розважальних ігор.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08:12:47.773Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 8px 0px"
					font="--lead"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 16px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					<Strong
						sm-text-align="left"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Наші переваги
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Чому обирають нас?
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Наша навчальна програма розроблена для покращення кожного аспекту гри гравця, від технічних навичок до фізичної підготовки та розумової міцності. Ми віримо в цілісний підхід до тренувань, який включає поради щодо харчування, стратегії запобігання травмам і сеанси спортивної психології, щоб переконатися, що наші спортсмени всебічно готові до гри.
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Тренери-експерти
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Усі наші тренери сертифіковані та мають великий досвід як гравців, так і тренерів.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Найсучасніше обладнання
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Ми надаємо першокласне обладнання для тренувань і засоби для імітації умов професійної гри.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Індивідуальні програми тренувань
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Кожен гравець отримує індивідуальну увагу, гарантуючи, що він розвивається в найкращому темпі.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Культура, орієнтована на цінності
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Наша академія пропагує повагу, чесність і наполегливість як основні цінності в усіх наших навчальних програмах.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				justify-content="center"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				width="75%"
			>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						position="absolute"
						display="block"
						right={0}
						min-height="100%"
						bottom={0}
						src="https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08:12:47.770Z"
						object-fit="cover"
						width="100%"
						top={0}
						left={0}
						srcSet="https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				justify-content="space-between"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Розпочніть свій шлях до футбольної досконалості з футбольною академією Future Champions.  Приєднуйтесь до нас сьогодні та станьте частиною майбутнього футболу.
				</Text>
				<Box
					height="243px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="243px"
					overflow-y="hidden"
					border-radius="100%"
				>
					<Image
						right={0}
						bottom={0}
						min-height="100%"
						src="https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-4.jpg?v=2024-06-12T08:12:47.805Z"
						object-fit="cover"
						width="100%"
						left={0}
						position="absolute"
						display="block"
						top={0}
						srcSet="https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-4.jpg?v=2024-06-12T08%3A12%3A47.805Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-4.jpg?v=2024-06-12T08%3A12%3A47.805Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-4.jpg?v=2024-06-12T08%3A12%3A47.805Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-4.jpg?v=2024-06-12T08%3A12%3A47.805Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-4.jpg?v=2024-06-12T08%3A12%3A47.805Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-4.jpg?v=2024-06-12T08%3A12%3A47.805Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-4.jpg?v=2024-06-12T08%3A12%3A47.805Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Nav>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Nav>
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});